import { PanelPlugin } from '@grafana/data';
import { SimplePanel } from './SimplePanel';
import { HCsrc } from './help-center/help-center-script';

interface SimpleOptions {
  helpCenterSrc: string;
  showHelpCenter: boolean;
}

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder
    .addBooleanSwitch({
      path: 'showHelpCenter',
      name: 'Activation',
      description: 'Show Asystom Help Center (enabled by default)',
      defaultValue: true,
      category: ['Help Center'],
    })
    .addTextInput({
      path: 'helpCenterSrc',
      name: 'URL',
      description: 'Paste Help Center URL',
      defaultValue: HCsrc,
      category: ['Help Center'],
    });
});
